.game-container{
  text-align: center;
}

.game-iframe{
  border: none;
  width: 1000px;
  height: 800px;
}

.game-isnt-loading{
  color:white;
}

.game-isnt-loading a{
  color: #7af347;
  cursor: pointer;
  text-decoration: underline #7af347
}
.games-grid{
  padding: 25px;
  display: grid;
  gap: 1rem;

  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.game-card{
  text-align: center;
  background-color: rgb(66, 66, 66);
  cursor: pointer;
  max-width: 250px;
  transition: .5s;
}

.game-card:hover{
  background-color: rgb(93, 93, 93);
}

.game-card h1{
  color:#7af347;
}

.game-card p{
  color: white;
}


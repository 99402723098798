.main-nav {
  padding: 25px;
  display: flex;
}

.main-nav li {
  display: inline-block;
  margin-left: 25px;
  
}

.nav-btn {
  top: -15%;
  width: 100px;
  height: 50px;
  background-color: var(--background-color);
  color: white;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 125%;
  font-weight: 700;
  --background-color: rgb(24, 24, 24);
}

.btn-underline::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--border-size);
  background-color: var(--accent-color);

  transition: transform 300ms ease-in-out;
  transform: scaleX(0);

  --border-size: 4px;
  --accent-color: #7af347;
}

.btn-underline:hover::before,
.btn-underline:focus::before {
  transform: scaleX(1);
}

.dropdown-btn{
  width: 130px;
}

.dropdown-content{
  display: none;
  position: absolute;
  background-color: #333333;
  min-width: 130px;
  max-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  text-align: center;
}

.dropdown-content a{
  color: white;
  cursor: pointer;
  margin-top: 5em;
  font-weight: 500;
  text-decoration: none;
}

.dropdown-content a:hover{
  color: #7af347;
  text-decoration: #7af347 underline;
}

.show-dropdown{
  display: block;
}

.main-logo {
  cursor: pointer;
  font-size: 3em;
  font-weight: 500;
  letter-spacing: 15px;
  color: rgb(44, 44, 44);
  text-transform: uppercase;
  text-align: center;
  /*-webkit-box-reflect: below 1px linear-gradient(transparent, #7af347);*/
  line-height: 0.7em;
  outline: none;
  animation: logo-text-small 20s linear infinite;
  animation-play-state: running;
  margin-right: 1em;
}

.main-logo:hover{
  animation-play-state: running;
  animation: logo-text-large 3s linear infinite;
}

@keyframes logo-text-small {
  0% {
    color: #fff;
    text-shadow: 0 0 5px #7af347, 0 0 10px #7af347, 0 0 20px #7af347,
      0 0 40px #7af347, 0 0 80px #7af347;
  }
  50% {
    color: #fff;
    text-shadow: 0 0 10px #7af347, 0 0 20px #7af347, 0 0 40px #7af347,
      0 0 100px #7af347, 0 0 200px #7af347;
  }
  100% {
    color: #fff;
    text-shadow: 0 0 5px #7af347, 0 0 10px #7af347, 0 0 20px #7af347,
      0 0 40px #7af347, 0 0 80px #7af347;
  }
}

@keyframes logo-text-large {
  0% {
    color: #fff;
    text-shadow: 0 0 5px #7af347, 0 0 10px #7af347, 0 0 20px #7af347,
      0 0 40px #7af347, 0 0 80px #7af347;
  }
  50% {
    color: #fff;
    text-shadow: 0 0 10px #7af347, 0 0 20px #7af347, 0 0 80px #7af347,
      0 0 100px #7af347, 0 0 200px #7af347;
  }
  100% {
    color: #fff;
    text-shadow: 0 0 5px #7af347, 0 0 10px #7af347, 0 0 20px #7af347,
      0 0 40px #7af347, 0 0 80px #7af347;
  }
}
